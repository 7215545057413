const steps = [
    {
        icon: "mdi-math-compass",
        id: 'study',
        tab_link_id: 0
    }
    ,
    {
        icon: "mdi-cogs",
        id: "development",
        tab_link_id: 1
    },
    {
        icon: "mdi-robot-industrial",
        id: "industrialization",
        tab_link_id: 6
    },
    {
        icon: "mdi-factory",
        id: "manufacturing",
        tab_link_id: 7
    }
];


export { steps }