<template>
  <div class="border-bottom">
    <v-container
      class="steps"
      :class="{
        'mt-7 mb-0': $vuetify.breakpoint.smAndDown,
        'mt-15 mb-6': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <v-row class="text-center">
        <div
          class="step col-6 col-lg-3"
          :class="{
            'col-12 py-2 px-4': $vuetify.breakpoint.xsOnly,
          }"
          v-for="(item, i) in items"
          :key="i"
        >
          <!-- <router-link
            :to="'/services#' + item.tab_link_id"
            custom
            v-slot="{ navigate }"
          >
            <card
              :height="$vuetify.breakpoint.smAndUp ? card_height : null"
              @click="navigate"
            >
              <div :ref="'card_inner_div_' + i">
                <v-icon
                  size="30"
                  :x-large="$vuetify.breakpoint.mdAndUp"
                  color="dark"
                >
                  {{ item.icon }}
                </v-icon>
                <br />
                <h4
                  class="h4_cards"
                  :class="{
                    'text-h6': $vuetify.breakpoint.smOnly,
                    'body-1': $vuetify.breakpoint.xsOnly,
                  }"
                >
                  {{ $t("pages_specific.main_page.steps." + item.id + ".title") }}
                </h4>
                <p class="pb-1" v-if="$vuetify.breakpoint.smAndUp">
                  {{ $t("pages_specific.main_page.steps." + item.id + ".text") }}
                </p>
              </div>
            </card>
          </router-link> -->

          <router-link to="/services#content" custom v-slot="{ navigate }">
            <card
              @click="navigate"
              :height="$vuetify.breakpoint.smAndUp ? card_height : null"
            >
              <div :ref="'card_inner_div_' + i">
                <v-icon
                  size="30"
                  :x-large="$vuetify.breakpoint.mdAndUp"
                  color="dark"
                >
                  {{ item.icon }}
                </v-icon>
                <br />
                <h4
                  class="h4_cards"
                  :class="{
                    'text-h6': $vuetify.breakpoint.smOnly,
                    'body-1 font-weight-medium': $vuetify.breakpoint.xsOnly,
                  }"
                >
                  {{
                    $t("pages_specific.main_page.steps." + item.id + ".title")
                  }}
                </h4>
                <p class="pb-1">
                  {{
                    $t("pages_specific.main_page.steps." + item.id + ".text")
                  }}
                </p>
              </div>
            </card>
          </router-link>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Card from "../custom_styling/Card.vue";
import { steps } from "@/data_for_components/home_page";
export default {
  components: { Card },

  data: () => ({
    items: steps,
    card_height: "14.5em",
  }),

  created() {
    window.addEventListener("resize", this.onResize);
  },

  mounted() {
    // Additionally, trigger onResize when the component is mounted
    this.onResize();
  },

  updated() {
    // Additionally, trigger onResize when the component is updated
    this.onResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      if (null != this.$refs && this.$refs["card_inner_div_0"]) {
        // Attention ! This code expects exactly 4 steps elements !
        let max_height = Math.max(
          this.$refs["card_inner_div_0"][0].clientHeight,
          this.$refs["card_inner_div_1"][0].clientHeight,
          this.$refs["card_inner_div_2"][0].clientHeight,
          this.$refs["card_inner_div_3"][0].clientHeight
        );

        // mt-7 = 28 pixels
        this.card_height = max_height + 28;
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  padding: 1em 1em 0 1em;
}

.steps {
  margin-top: 2em;
}
/* .steps h4 {
  text-decoration: underline;
  text-decoration-color: #f1d135;
} */

/* .step:hover h4 {
  background-color: #f1d135;
}
.step:hover .v-card {
  background-color: #f9f9f9;
} */

.step {
  min-width: min-content;
  /* cursor: pointer; */
}
</style>
