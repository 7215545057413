var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-bottom"},[_c('v-container',{staticClass:"steps",class:{
      'mt-7 mb-0': _vm.$vuetify.breakpoint.smAndDown,
      'mt-15 mb-6': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('v-row',{staticClass:"text-center"},_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"step col-6 col-lg-3",class:{
          'col-12 py-2 px-4': _vm.$vuetify.breakpoint.xsOnly,
        }},[_c('router-link',{attrs:{"to":"/services#content","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('card',{attrs:{"height":_vm.$vuetify.breakpoint.smAndUp ? _vm.card_height : null},on:{"click":navigate}},[_c('div',{ref:'card_inner_div_' + i,refInFor:true},[_c('v-icon',{attrs:{"size":"30","x-large":_vm.$vuetify.breakpoint.mdAndUp,"color":"dark"}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('br'),_c('h4',{staticClass:"h4_cards",class:{
                  'text-h6': _vm.$vuetify.breakpoint.smOnly,
                  'body-1 font-weight-medium': _vm.$vuetify.breakpoint.xsOnly,
                }},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.main_page.steps." + item.id + ".title"))+" ")]),_c('p',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.$t("pages_specific.main_page.steps." + item.id + ".text"))+" ")])],1)])]}}],null,true)})],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }